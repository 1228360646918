define("discourse/plugins/docker_manager/discourse/routes/update-show", ["exports", "@ember/routing/route", "@ember/service", "discourse/plugins/docker_manager/discourse/models/repo"], function (_exports, _route, _service, _repo2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UpgradeShow = _exports.default = (_class = class UpgradeShow extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "upgradeStore", _descriptor, this);
    }
    model(params) {
      if (params.id === "all") {
        return _repo2.default.findAll();
      }
      return _repo2.default.find(params.id);
    }
    async afterModel(model) {
      if (Array.isArray(model)) {
        const repos = await _repo2.default.findLatestAll();
        for (const repoData of repos) {
          const repo = model.find(_repo => _repo.path === repoData.path);
          if (!repo) {
            return;
          }
          delete repoData.path;
          for (const [key, value] of Object.entries(repoData)) {
            repo.latest[key] = value;
          }
        }
        const progress = await _repo2.default.findAllProgress(model.filter(repo => !repo.upToDate));
        this.upgradeStore.reset({
          consoleOutput: progress.logs,
          progressPercentage: progress.percentage,
          upgradeStatus: progress.status,
          repos
        });
        return;
      }
      await _repo2.default.findUpgrading();
      await model.findLatest();
      const progress = await model.findProgress();
      this.upgradeStore.reset({
        consoleOutput: progress.logs,
        progressPercentage: progress.percentage,
        upgradeStatus: progress.status,
        repos: [model.id]
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "upgradeStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});